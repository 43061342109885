// App.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAsyncActions } from "./hooks/async-actions";

import Home from "./components/Home/Home";
import MainHeader from "./components/MainHeader/MainHeader";
import Lobby from "./components/Lobby/Lobby";

let isInitial = true;

function App() {
  const dispatch = useDispatch();
  const { reconnectSession } = useAsyncActions();
  const token = useSelector((state) => state.auth.token);
  const email = useSelector((state) => state.auth.email);
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    if (isInitial && token) {
      dispatch(reconnectSession(token, email));
    }
    isInitial = false;
  }, [reconnectSession, email, token, dispatch]);

  const showHeader = loggedIn !== false;

  return (
    <React.Fragment>
      {showHeader && <MainHeader loggedIn={loggedIn} />}
      <main>
        {loggedIn === false && <Lobby />}
        {loggedIn === true && <Home />}
      </main>
    </React.Fragment>
  );
}

export default App;
