import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

import { uiActions } from "../../store/ui-slice";
import { useAsyncActions } from "../../hooks/async-actions";
import ItemIcon from "./ItemIcon";

const PropertyDetails = () => {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const details = useSelector((state) => state.ui.selectedProperty);
  const { removeItem } = useAsyncActions();

  const lowerFilter = (filter || "").toLocaleLowerCase();

  const matchesFilter = (str) =>
    !lowerFilter || (str || "").toLocaleLowerCase().includes(lowerFilter);

  const filteredItems = details.items.filter((i) => matchesFilter(i.name));

  const handleChangeFilter = (event) => setFilter(event.target.value);
  const handleAddItem = (event) => dispatch(uiActions.newItem());
  const handleEdit = (item) => dispatch(uiActions.viewItem(item));

  // confimation delete item dialog
  const [confirmState, setConfirmState] = useState({ open: false, item: null });
  const confirmDeleteName = confirmState.item ? confirmState.item.name : "";

  const handleConfirmDeleteOpen = (item) =>
    setConfirmState({ open: true, item });

  const handleConfirmDeleteClose = (confirmed) => {
    if (confirmed) {
      dispatch(removeItem(confirmState.item));
    }
    setConfirmState({ ...confirmState, open: false });
  };

  const handleClearSearch = () => setFilter("");

  return (
    <div>
      <Stack sx={{ mt: 2 }} spacing={1}>
        <Typography variant="h5">{details.property.address}</Typography>

        <FormControl fullWidth sx={{ mt: 5 }}>
          <InputLabel htmlFor="search-input">Search</InputLabel>
          <OutlinedInput
            id="search-input"
            type="text"
            autoFocus
            value={filter}
            onChange={handleChangeFilter}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={!filter}
                  aria-label="clear search"
                  onClick={handleClearSearch}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Search"
            sx={{ borderRadius: 4 }}
          />
        </FormControl>

        <List disablePadding={true}>
          <ListItem onClick={handleAddItem}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add a new item..." />
            </ListItemButton>
          </ListItem>
          {filteredItems.map((item) => (
            <ListItem
              key={item}
              secondaryAction={
                <Tooltip title={"Remove " + item.name}>
                  <IconButton onClick={() => handleConfirmDeleteOpen(item)}>
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar>
                    {/* <DeviceUnknownIcon /> */}
                    <ItemIcon type={item.type} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={item.type}
                  onClick={() => handleEdit(item)}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>

      <Dialog
        fullScreen={false}
        open={confirmState.open}
        onClose={() => handleConfirmDeleteClose(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Remove ${confirmDeleteName}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Removing an item is a irreversible action. Are you sure you want to
            proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleConfirmDeleteClose(false)}>
            No
          </Button>
          <Button autoFocus onClick={() => handleConfirmDeleteClose(true)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PropertyDetails;
