import React from "react";
import { useSelector } from "react-redux";

import classes from "./Home.module.css";
import PropertyList from "./PropertyList";
import PropertyDetails from "./PropertyDetails";
import NewItem from "./NewItem";
import ViewItem from "./ViewItem";

const Home = () => {
  const view = useSelector((state) => state.ui.view);

  return (
    <div className={classes.home}>
      {view === "home" && <PropertyList />}
      {view === "property" && <PropertyDetails />}
      {view === "newItem" && <NewItem />}
      {view === "viewItem" && <ViewItem />}
    </div>
  );
};

export default Home;
