import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncActions } from "../../hooks/async-actions";
import { useMetadataMixer } from "../../hooks/use-metadata";
import { uiActions } from "../../store/ui-slice";
import ItemWiz from "./ItemWiz";

const NewItem = () => {
  const property = useSelector((state) => state.ui.selectedProperty);
  const metadata = useSelector((state) => state.ui.metadata);
  const { getItemTypes, getLocations: getPurchaseLocations } = useMetadataMixer(
    metadata,
    property
  );
  const { addNewItem } = useAsyncActions();
  const dispatch = useDispatch();

  const item = { propertyId: property.property.id };

  const handleSubmit = (editedItem) => dispatch(addNewItem(editedItem));

  const handleBack = (event) => dispatch(uiActions.backFromItem());

  return (
    <div>
      <ItemWiz
        item={item}
        categories={getItemTypes()}
        locations={getPurchaseLocations()}
        wizardMode={true}
        onCancel={handleBack}
        onComplete={handleSubmit}
      />
    </div>
  );
};

export default NewItem;
