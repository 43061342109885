import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Email from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import LoginIcon from "@mui/icons-material/Login";

import classes from "./SignIn.module.css";
import { useAsyncActions } from "../../hooks/async-actions";
import { authActions } from "../../store/auth-slice";
import useInput from "../../hooks/use-input";

const SignIn = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);
  const processing = useSelector((state) => state.ui.processing);
  const asyncActions = useAsyncActions();

  const isNotEmpty = (value) => value && value.trim() !== "";
  const isValidEmail = (value) => isNotEmpty(value) && value.includes("@");

  // email
  const {
    value: enteredEmail,
    isValid: emailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isValidEmail, email);

  // password
  const {
    value: enteredPassword,
    showPassword,
    isValid: passwordIsValid,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordChangedHandler,
    inputBlurHandler: passwordBlurHandler,
    toggleShowPasswordHandler,
  } = useInput(isNotEmpty);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignup = (event) => {
    event.preventDefault();
    dispatch(authActions.beginSignup());
  };

  const formIsValid = emailIsValid && passwordIsValid;

  const loginHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      dispatch(asyncActions.login(enteredEmail, enteredPassword));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      loginHandler(event);
    }
  };

  return (
    <Card
      className={classes.login}
      sx={{ borderColor: "primary.main", borderRadius: 4 }}
    >
      <form noValidate>
        <div>
          <Typography variant="h5">Login into HomeBook 101</Typography>

          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel htmlFor="email-input">Email</InputLabel>
            <OutlinedInput
              id="email-input"
              type="email"
              autoFocus
              error={emailInputHasError}
              value={enteredEmail}
              onChange={emailChangedHandler}
              onBlur={emailBlurHandler}
              onKeyPress={handleKeyPress}
              startAdornment={
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              }
              label="Email"
              sx={{ borderRadius: 4 }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel htmlFor="password-input">Password</InputLabel>
            <OutlinedInput
              id="password-input"
              type={showPassword ? "text" : "password"}
              error={passwordInputHasError}
              value={enteredPassword}
              onChange={passwordChangedHandler}
              onBlur={passwordBlurHandler}
              onKeyPress={handleKeyPress}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPasswordHandler}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              sx={{ borderRadius: 4 }}
            />
          </FormControl>

          <Typography align="center" sx={{ mt: 2 }}>
            {!asyncActions.lastError && <p>&nbsp;</p>}
            {asyncActions.lastError && (
              <p className={classes.error}>{asyncActions.lastError}</p>
            )}
          </Typography>

          <Typography align="center" sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              endIcon={<LoginIcon />}
              onClick={loginHandler}
              disabled={processing || !formIsValid}
              sx={{ borderRadius: 4 }}
            >
              Log In
            </Button>
          </Typography>

          <Typography align="center" sx={{ mt: 4 }}>
            <Link href="#" underline="none" sx={{ color: "#E05A3A" }}>
              Forgot your password?
            </Link>
          </Typography>

          <Typography align="center" sx={{ mt: 4 }}>
            Don't have an account?{" "}
            <Link href="#" underline="none" onClick={handleSignup}>
              Sign Up.
            </Link>
          </Typography>
        </div>
      </form>
    </Card>
  );
};

export default SignIn;
