import { createSlice } from "@reduxjs/toolkit";

const email = localStorage.getItem("auth-email");
const token = localStorage.getItem("auth-token");
const loggedIn = email && token ? null : false;

const initialAuthState = {
  email,
  token,
  loggedIn,
  signup: {
    active: false,
  },
};

const updateLocalStorageAuth = (email, token) => {
  localStorage.setItem("auth-email", email);
  localStorage.setItem("auth-token", token);
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    beginSignup(state) {
      state.signup.active = true;
    },
    endSignup(state) {
      state.signup.active = false;
    },
    signupComplete(state, action) {
      state.signup.active = false;
      state.email = action.payload;
      state.token = null;
      state.loggedIn = false;
    },
    loginSucceeded(state, action) {
      state.loggedIn = true;
      state.token = action.payload.token;
      state.email = action.payload.email;
      updateLocalStorageAuth(state.email, state.token);
    },
    logout(state) {
      state.loggedIn = false;
      state.token = "";
      updateLocalStorageAuth(state.email, "");
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
