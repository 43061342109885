import { createSlice } from "@reduxjs/toolkit";

const initialAppState = {
  view: "home",
  processing: false,
  propertyList: {
    properties: [],
    newProperty: {
      active: false,
    },
  },
  selectedProperty: {},
  selectedItem: {},
  metadata: {
    types: [],
    locations: [],
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialAppState,
  reducers: {
    init(state, action) {
      state.view = "home";
      state.propertyList.properties = action.payload.properties;
      state.selectedProperty = {};
      state.selectedItem = {};
      state.metadata = action.payload.metadata;
    },
    startProcess(state) {
      state.processing = true;
    },
    endProcess(state) {
      state.processing = false;
    },
    goHome(state, action) {
      state.view = "home";
      state.selectedProperty = {};
      state.selectedItem = {};
    },
    begingAddingProperty(state) {
      state.propertyList.newProperty.active = true;
    },
    endAddingProperty(state) {
      state.propertyList.newProperty.active = false;
    },
    addNewProperty(state, action) {
      state.propertyList.newProperty.active = false;
      state.propertyList.properties.push(action.payload);
    },
    removeProperty(state, action) {
      const properties = state.propertyList.properties;
      const index = properties.findIndex((i) => i.id === action.payload.id);
      properties.splice(index, 1);
    },
    loadProperty(state, action) {
      state.view = "property";
      state.selectedProperty = action.payload;
    },
    addItem(state, action) {
      state.view = "property";
      state.selectedProperty.items.push(action.payload);
    },
    loadItem(state, action) {
      state.view = "item";
      state.selectedItem = action.payload;
    },
    newItem(state, action) {
      state.view = "newItem";
    },
    viewItem(state, action) {
      state.view = "viewItem";
      state.selectedItem = action.payload;
    },
    removeItem(state, action) {
      const items = state.selectedProperty.items;
      const index = items.findIndex((i) => i.id === action.payload.id);
      items.splice(index, 1);
    },
    updateItem(state, action) {
      const items = [...state.selectedProperty.items];
      const index = items.findIndex((i) => i.id === state.selectedItem.id);
      items[index] = action.payload;
      state.selectedProperty.items = items;
      state.view = "property";
      state.selectedItem = {};
    },
    backFromItem(state) {
      state.view = "property";
      state.selectedItem = {};
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
