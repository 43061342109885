import React from "react";
import { useSelector } from "react-redux";

import SignIn from "../SignIn/SignIn";
import SignUp from "../Signup/Signup";

const Lobby = () => {
  const showSignup = useSelector((state) => state.auth.signup.active);

  return (
    <React.Fragment>
      {!showSignup && <SignIn />}
      {showSignup && <SignUp />}
    </React.Fragment>
  );
};

export default Lobby;
