import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import LightIcon from "@mui/icons-material/Light";
import BuildIcon from "@mui/icons-material/Build";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import DevicesIcon from "@mui/icons-material/Devices";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ChairIcon from "@mui/icons-material/Chair";
import WatchIcon from "@mui/icons-material/Watch";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import KitchenIcon from "@mui/icons-material/Kitchen";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import React from "react";

const ItemIcon = (props) => {
  const type = (props.type || "").toLowerCase();

  const iconMap = {
    appliance: <CoffeeMakerIcon />,
    lighting: <LightIcon />,
    tool: <BuildIcon />,
    electronics: <ElectricalServicesIcon />,
    device: <DevicesIcon />,
    part: <MiscellaneousServicesIcon />,
    furniture: <ChairIcon />,
    watch: <WatchIcon />,
    washer: <LocalLaundryServiceIcon />,
    drier: <LocalLaundryServiceIcon />,
    "dish washer": <LocalLaundryServiceIcon />,
    fridge: <KitchenIcon />,
    camera: <PhotoCameraIcon />,
  };

  return iconMap[type] || <DeviceUnknownIcon />;
};

export default ItemIcon;
