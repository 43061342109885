import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Alert,
  AlertTitle,
  Link,
} from "@mui/material";
import React, { useState } from "react";

import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LanguageIcon from "@mui/icons-material/Language";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box } from "@mui/system";

const trimDate = (date) => date.toISOString().slice(0, 10);
const makeShortDate = () => trimDate(new Date());
const makeLocalDateTime = () => new Date().toLocaleString();
const parseFormattedDate = (text, defDate) =>
  text ? trimDate(new Date(text)) : defDate;

const ItemWiz = (props) => {
  const item = props.item || {};

  const [state, setState] = useState({
    ...item,
    type: item.type || "",
    name: item.name || "",
    detals: item.details || "",
    date: parseFormattedDate(item.date, makeShortDate()),
    source: item.source || "",
    warrantyUntil: parseFormattedDate(item.warrantyUntil, makeShortDate()),
    warrantyPhone: item.warrantyPhone || "",
    warrantyEmail: item.warrantyEmail || "",
    warrantyUrl: item.warrantyUrl || "",
    notes: item.notes || [],
    note: "",
  });
  const [step, setStep] = useState(props.wizardMode ? 1 : 0);
  const [readonly, setReadonly] = useState(props.readonly);

  const onFieldChange = (prop) => (event) =>
    setState({ ...state, [prop]: event.target.value });

  const onSearchChange = (prop) => (event, value) =>
    setState({ ...state, [prop]: value });

  const handleAddNote = () => {
    const noteText = state.note;
    const newNote = { date: makeLocalDateTime(), text: noteText };
    const newNotes = [newNote].concat(state.notes);
    setState({
      ...state,
      note: "",
      notes: newNotes,
    });
  };

  const handleRemoveNote = (note) => {
    const newNotes = [...state.notes];
    newNotes.splice(note, 1);
    setState({ ...state, notes: newNotes });
  };

  const handleIgnoredMouseDown = (event) => event.preventDefault();

  const handleNext = () => setStep(step + 1);
  const handlePrev = () => setStep(step - 1);
  const handleFinish = () => props.onComplete && props.onComplete(state);
  const matchStep = (n) => !step || step === n;
  const isLastStep = () => step === 4 || !props.wizardMode;

  const handleEdit = (event) => {
    event.preventDefault();
    setReadonly(false);
  };

  const nameHasError = !(state.name || "").trim();
  const validNote = state.note && state.note.trim();

  const typeLabel = "Category (what is this?)";
  const nameLabel = "Name or identification of this item";
  const detailsLabel = "Other relevant details";
  const dateLabel = "Adquired date";
  const sourceDetails = "From where did you get it?";
  const priceLabel = "Price";
  const untilLabel = "Warranty until";
  const warrantyPhoneLabel = "Contact phone";
  const warrantyEmailLabel = "Contact email";
  const warrantyUrlLabel = "Contact website";
  const labelNote = "Enter a new note";

  return (
    <div>
      {/* name and details */}
      {matchStep(1) && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          {/* item heading */}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Typography variant="h5">
              {readonly ? state.name : "About this Item"}
            </Typography>
            <Box sx={{ flex: "1 1 auto" }} />
            {readonly && (
              <Link href="#" underline="none" onClick={handleEdit}>
                Edit
              </Link>
            )}
          </Box>

          {/* type */}
          <Autocomplete
            inputValue={state.type}
            onInputChange={onSearchChange("type")}
            freeSolo
            id="item-type"
            disableClearable
            disabled={readonly}
            options={props.categories || []}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                label={typeLabel}
                autoFocus
                disabled={readonly}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />

          {/* name or identifier */}
          <FormControl fullWidth>
            <InputLabel htmlFor="fname-input">{nameLabel}</InputLabel>
            <OutlinedInput
              id="fname-input"
              value={state.name}
              error={nameHasError}
              onChange={onFieldChange("name")}
              label={nameLabel}
              disabled={readonly}
              endAdornment={
                <InputAdornment position="end">
                  <FingerprintIcon />
                </InputAdornment>
              }
            />
          </FormControl>

          {/* details */}
          <FormControl fullWidth>
            <InputLabel htmlFor="details-input">{detailsLabel}</InputLabel>
            <OutlinedInput
              id="details-input"
              value={state.details}
              onChange={onFieldChange("details")}
              label={detailsLabel}
              multiline
              rows={3}
              disabled={readonly}
              endAdornment={
                <InputAdornment position="end">
                  <DocumentScannerIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Stack>
      )}

      {/* adquisition details */}
      {matchStep(2) && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography variant="h5">Adquisition Details</Typography>

          <FormControl fullWidth>
            <InputLabel htmlFor="date-input">{dateLabel}</InputLabel>
            <OutlinedInput
              id="date-input"
              value={state.date}
              type="date"
              onChange={onFieldChange("date")}
              label={dateLabel}
              disabled={readonly}
            />
          </FormControl>

          <Autocomplete
            inputValue={state.source}
            onInputChange={onSearchChange("source")}
            freeSolo
            id="item-source"
            disableClearable
            options={props.locations || []}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                label={sourceDetails}
                error={nameHasError}
                disabled={readonly}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="price-input">{priceLabel}</InputLabel>
            <OutlinedInput
              id="price-input"
              type="number"
              value={state.price}
              onChange={onFieldChange("price")}
              label={priceLabel}
              disabled={readonly}
              endAdornment={
                <InputAdornment position="end">
                  <AttachMoneyIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Stack>
      )}

      {/* warranty */}
      {matchStep(3) && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography variant="h5">Warranty Details</Typography>

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Is there warranty on this item?
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="no"
              row
              name="radio-buttons-group"
              onChange={(event, value) =>
                setState({ ...state, warranty: value === "yes" })
              }
            >
              <FormControlLabel
                value="yes"
                control={<Radio checked={state.warranty} disabled={readonly} />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio checked={!state.warranty} disabled={readonly} />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="warranty-date-input">{untilLabel}</InputLabel>
            <OutlinedInput
              id="warranty-date-input"
              value={state.warrantyUntil}
              type="date"
              onChange={onFieldChange("warrantyUntil")}
              label={dateLabel}
              disabled={readonly || !state.warranty}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="warraty-phone-input">
              {warrantyPhoneLabel}
            </InputLabel>
            <OutlinedInput
              id="warraty-phone-input"
              type="phone"
              value={state.warrantyPhone}
              onChange={onFieldChange("warrantyPhone")}
              label={warrantyPhoneLabel}
              disabled={readonly || !state.warranty}
              endAdornment={
                <InputAdornment position="end">
                  <PhoneIcon />
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="warraty-email-input">
              {warrantyEmailLabel}
            </InputLabel>
            <OutlinedInput
              id="warraty-email-input"
              type="email"
              value={state.warrantyEmail}
              onChange={onFieldChange("warrantyEmail")}
              label={warrantyEmailLabel}
              disabled={readonly || !state.warranty}
              endAdornment={
                <InputAdornment position="end">
                  <MailOutlineIcon />
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="warraty-email-input">
              {warrantyUrlLabel}
            </InputLabel>
            <OutlinedInput
              id="warraty-email-input"
              type="text"
              value={state.warrantyUrl}
              onChange={onFieldChange("warrantyUrl")}
              label={warrantyUrlLabel}
              disabled={readonly || !state.warranty}
              endAdornment={
                <InputAdornment position="end">
                  <LanguageIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Stack>
      )}

      {/* notes         */}
      {matchStep(4) && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          {(!readonly || state.notes.length > 0) && (
            <Typography variant="h5">Other Notes</Typography>
          )}

          {!readonly && (
            <FormControl fullWidth>
              <InputLabel htmlFor="fname-input">{labelNote}</InputLabel>
              <OutlinedInput
                id="fname-input"
                value={state.note}
                onChange={onFieldChange("note")}
                label={labelNote}
                multiline
                rows={3}
                disabled={readonly}
                endAdornment={
                  !readonly && (
                    <InputAdornment position="end" sx={{ mr: 1 }}>
                      <IconButton
                        onClick={handleAddNote}
                        onMouseDown={handleIgnoredMouseDown}
                        disabled={!validNote}
                        edge="end"
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          )}

          <Stack spacing={2}>
            {state.notes.map((n, i) => (
              <Alert
                key={`note-${i}`}
                severity="success"
                onClose={readonly ? null : () => handleRemoveNote(i)}
              >
                <AlertTitle>
                  {n.date ? new Date(n.date).toLocaleString() : ""}
                </AlertTitle>
                {n.text}
              </Alert>
            ))}
          </Stack>
        </Stack>
      )}

      {/* buttons         */}
      {true && (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {props.wizardMode && (
            <Button
              variant="outlined"
              disabled={step === 1}
              onClick={handlePrev}
              sx={{ mr: 1 }}
              startIcon={<ArrowLeftIcon />}
            >
              Back
            </Button>
          )}

          <Box sx={{ flex: "1 1 auto" }} />

          {props.onCancel && (
            <Button variant="outlined" onClick={props.onCancel} sx={{ mr: 1 }}>
              Cancel
            </Button>
          )}

          {!readonly && (
            <Button
              variant="outlined"
              disabled={nameHasError}
              onClick={isLastStep() ? handleFinish : handleNext}
              endIcon={<ArrowRightIcon />}
            >
              {isLastStep() ? "Save" : "Next"}
            </Button>
          )}
        </Box>
      )}
    </div>
  );
};

export default ItemWiz;
