export const useMetadataMixer = (metadata, property) => {
  const items = property ? property.items : [];

  const mergeAndSort = (staticList, itemMapper) => {
    const filtered = items.map(itemMapper);
    const s = new Set(staticList.concat(filtered).filter((e) => !!e));
    return [...s].sort();
  };

  const getItemTypes = () => mergeAndSort(metadata.types, (i) => i.type);
  const getLocations = () => mergeAndSort(metadata.locations, (i) => i.source);

  return {
    getItemTypes,
    getLocations,
  };
};
