import React from "react";

import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { uiActions } from "../../store/ui-slice";

const MainHeader = (props) => {
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.ui.processing);
  const email = useSelector((state) => state.auth.email);

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  const handleGoHome = () => {
    dispatch(uiActions.goHome());
  };

  const getTitle = () => (props.loggedIn ? email : "Home Book 101");

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleGoHome}
          >
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {getTitle()}
          </Typography>
          {props.loggedIn && (
            <Button color="inherit" onClick={handleLogout}>
              Log out
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {processing && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};

export default MainHeader;
