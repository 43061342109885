import { useReducer } from "react";

const initialInputState = {
  value: "",
  isTouched: false,
  showPassword: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return { value: action.value, isTouched: state.isTouched };
  }
  if (action.type === "BLUR") {
    return { isTouched: true, value: state.value };
  }
  if (action.type === "RESET") {
    return { isTouched: false, value: "" };
  }
  if (action.type === "TOGGLE-SHOW") {
    return { ...state, showPassword: !state.showPassword };
  }
  return { ...state };
};

const useInput = (validateValue, initialValue) => {
  const [inputState, dispatch] = useReducer(inputStateReducer, {
    ...initialInputState,
    value: initialValue || "",
  });

  const valueIsValid = !validateValue || validateValue(inputState.value);
  const hasError = !valueIsValid && inputState.isTouched;

  const valueChangeHandler = (event) =>
    dispatch({ type: "INPUT", value: event.target.value });

  const inputBlurHandler = (event) => dispatch({ type: "BLUR" });

  const toggleShowPasswordHandler = (event) =>
    dispatch({ type: "TOGGLE-SHOW" });

  const reset = () => dispatch({ type: "RESET" });

  const resetValue = (value) => dispatch({ type: "INPUT", value });

  return {
    value: inputState.value,
    showPassword: inputState.showPassword,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    toggleShowPasswordHandler,
    reset,
    resetValue,
  };
};

export default useInput;
