import React, { useState } from "react";

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";

import HouseIcon from "@mui/icons-material/House";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { useDispatch, useSelector } from "react-redux";

import { useAsyncActions } from "../../hooks/async-actions";
import { uiActions } from "../../store/ui-slice";
import NewProperty from "./NewProperty";

const PropertyList = () => {
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.ui.propertyList.properties);
  const { loadPropertyItems, removeProperty } = useAsyncActions();

  const addingProperty = useSelector(
    (state) => state.ui.propertyList.newProperty.active
  );

  const handleSelectProperty = (p) => dispatch(loadPropertyItems(p));
  const handleAddProperty = () => dispatch(uiActions.begingAddingProperty());

  // confimation delete item dialog
  const [confirmState, setConfirmState] = useState({
    open: false,
    property: null,
  });

  const confirmDeleteName = confirmState.property
    ? confirmState.property.name
    : "";

  const handleConfirmDeleteOpen = (property) =>
    setConfirmState({ open: true, property });

  const handleConfirmDeleteClose = (confirmed) => {
    if (confirmed) {
      dispatch(removeProperty(confirmState.property));
    }
    setConfirmState({ ...confirmState, open: false });
  };
  return (
    <React.Fragment>
      {/* adding a new property */}
      {addingProperty && <NewProperty />}

      {/* listing properties */}
      {!addingProperty && (
        <List disablePadding={true}>
          {properties.map((p) => (
            <ListItem
              key={p}
              secondaryAction={
                <Tooltip title={"Remove " + p.name}>
                  <IconButton onClick={() => handleConfirmDeleteOpen(p)}>
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar>
                    <HouseIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={p.name}
                  secondary={p.address}
                  onClick={() => handleSelectProperty(p)}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem onClick={handleAddProperty}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add a new property..." />
            </ListItemButton>
          </ListItem>
        </List>
      )}

      <Dialog
        fullScreen={false}
        open={confirmState.open}
        onClose={() => handleConfirmDeleteClose(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Remove ${confirmDeleteName}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Removing a property is a irreversible action. Are you sure you want
            to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleConfirmDeleteClose(false)}>
            No
          </Button>
          <Button autoFocus onClick={() => handleConfirmDeleteClose(true)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PropertyList;
