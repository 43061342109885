import React from "react";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";

import { uiActions } from "../../store/ui-slice";
import { useAsyncActions } from "../../hooks/async-actions";
import useInput from "../../hooks/use-input";

const NewProperty = () => {
  const dispatch = useDispatch();
  const { addNewProperty, lastError } = useAsyncActions();

  const isValidName = (value) => value && value.trim() !== "";

  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameInputHasError,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput(isValidName);

  const {
    value: enteredAddress,
    isValid: enteredAddressIsValid,
    hasError: addressInputHasError,
    valueChangeHandler: addressChangedHandler,
    inputBlurHandler: addressBlurHandler,
  } = useInput(isValidName);

  const {
    value: enteredPhone,
    hasError: phoneInputHasError,
    valueChangeHandler: phoneChangedHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput();

  const handleCancel = () => dispatch(uiActions.endAddingProperty());

  const handleSave = () => {
    dispatch(
      addNewProperty({
        name: enteredName,
        address: enteredAddress,
        phone: enteredPhone,
      })
    );
  };

  const dissableSave = !enteredNameIsValid || !enteredAddressIsValid;

  return (
    <form noValidate>
      <Stack spacing={2}>
        <Typography variant="h5">New Property</Typography>

        <FormControl fullWidth>
          <InputLabel htmlFor="name-input">Name</InputLabel>
          <OutlinedInput
            id="name-input"
            type="text"
            autoFocus
            error={nameInputHasError}
            value={enteredName}
            onChange={nameChangedHandler}
            onBlur={nameBlurHandler}
            label="Name"
            sx={{ borderRadius: 4 }}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="address-input">Address</InputLabel>
          <OutlinedInput
            id="address-input"
            type="text"
            error={addressInputHasError}
            value={enteredAddress}
            onChange={addressChangedHandler}
            onBlur={addressBlurHandler}
            label="Address"
            sx={{ borderRadius: 4 }}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="phone-input">Phone Number</InputLabel>
          <OutlinedInput
            id="phone-input"
            type="text"
            error={phoneInputHasError}
            value={enteredPhone}
            onChange={phoneChangedHandler}
            onBlur={phoneBlurHandler}
            label="Phone Number"
            sx={{ borderRadius: 4 }}
          />
        </FormControl>

        {lastError && <Alert severity="error">{lastError}</Alert>}

        <Button onClick={handleSave} disabled={dissableSave}>
          Save
        </Button>

        <Button onClick={handleCancel}>Cancel</Button>
      </Stack>
    </form>
  );
};

export default NewProperty;
