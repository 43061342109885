import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAsyncActions } from "../../hooks/async-actions";
import { useMetadataMixer } from "../../hooks/use-metadata";
import { uiActions } from "../../store/ui-slice";
import ItemWiz from "./ItemWiz";

const ViewItem = () => {
  const item = useSelector((state) => state.ui.selectedItem);
  const property = useSelector((state) => state.ui.selectedProperty);
  const metadata = useSelector((state) => state.ui.metadata);
  const { getItemTypes, getLocations: getPurchaseLocations } = useMetadataMixer(
    metadata,
    property
  );

  const asyncActions = useAsyncActions();
  const dispatch = useDispatch();

  const handleSubmit = (editedItem) => {
    dispatch(
      asyncActions.updateItem({
        ...editedItem,
        id: item.id,
      })
    );
  };

  const handleBack = (event) => dispatch(uiActions.backFromItem());

  return (
    <div>
      <ItemWiz
        item={item}
        categories={getItemTypes()}
        locations={getPurchaseLocations()}
        wizardMode={false}
        readonly={true}
        onCancel={handleBack}
        onComplete={handleSubmit}
      />
    </div>
  );
};

export default ViewItem;
