import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Email from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import LoginIcon from "@mui/icons-material/Login";

import useInput from "../../hooks/use-input";

import ReCAPTCHA from "react-google-recaptcha";

import classes from "./Signup.module.css";
import { authActions } from "../../store/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncActions } from "../../hooks/async-actions";

const SignUp = () => {
  const dispatch = useDispatch();
  const { singupUser, lastError } = useAsyncActions();
  const processing = useSelector((state) => state.ui.processing);
  const [recaptcha, setRecaptcha] = useState(null);

  const isNotEmpty = (value) => value && value.trim() !== "";
  const isValidEmail = (value) => isNotEmpty(value) && value.includes("@");
  const isValidPassword = (value) => isNotEmpty(value) && value.length >= 8;

  // first-name
  const {
    value: enteredFirstName,
    isValid: firstNameValid,
    hasError: firstNameInputHasError,
    valueChangeHandler: firstNameChangedHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(isNotEmpty);

  // last name
  const {
    value: enteredLastName,
    isValid: lastNameValid,
    hasError: lastNameInputHasError,
    valueChangeHandler: lastNameChangedHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(isNotEmpty);

  // email
  const {
    value: enteredEmail,
    isValid: emailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isValidEmail);

  // password
  const {
    value: enteredPassword,
    isValid: passwordIsValid,
    showPassword,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordChangedHandler,
    inputBlurHandler: passwordBlurHandler,
    toggleShowPasswordHandler,
  } = useInput(isValidPassword);

  // confirm
  const {
    value: enteredConfirmPassword,
    showPassword: showConfirmPassword,
    hasError: passwordConfirmInputHasError,
    valueChangeHandler: passwordConfirmChangedHandler,
    inputBlurHandler: passwordConfirmBlurHandler,
    toggleShowPasswordHandler: toggleShowConfirmPasswordHandler,
  } = useInput(isValidPassword);

  const [passwordsMatch, setPasswordsMatch] = useState(true);

  useEffect(() => {
    setPasswordsMatch(enteredPassword === enteredConfirmPassword);
  }, [enteredPassword, enteredConfirmPassword]);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleRecatchaChange = (value) => setRecaptcha(value);

  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(authActions.endSignup());
  };

  const formIsValid =
    firstNameValid &&
    lastNameValid &&
    emailIsValid &&
    passwordIsValid &&
    passwordsMatch &&
    recaptcha;

  const signupHandler = (event) => {
    if (formIsValid) {
      dispatch(
        singupUser(
          enteredFirstName,
          enteredLastName,
          enteredEmail,
          enteredPassword,
          enteredConfirmPassword,
          recaptcha
        )
      );
    }
  };

  return (
    <Card
      className={classes.signup}
      sx={{ borderColor: "primary.main", borderRadius: 4 }}
    >
      <form noValidate>
        <div>
          <Typography variant="h5">Sign Up for HomeBook 101</Typography>

          <FormControl fullWidth sx={{ mt: 4 }}>
            <InputLabel htmlFor="fname-input">First name</InputLabel>
            <OutlinedInput
              id="fname-input"
              error={firstNameInputHasError}
              value={enteredFirstName}
              autoFocus
              onChange={firstNameChangedHandler}
              onBlur={firstNameBlurHandler}
              startAdornment={
                <InputAdornment position="start">
                  <PersonOutlineIcon />
                </InputAdornment>
              }
              label="First name"
              sx={{ borderRadius: 4 }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 4 }}>
            <InputLabel htmlFor="lname-input">Last name</InputLabel>
            <OutlinedInput
              id="lname-input"
              error={lastNameInputHasError}
              value={enteredLastName}
              onChange={lastNameChangedHandler}
              onBlur={lastNameBlurHandler}
              startAdornment={
                <InputAdornment position="start">
                  <PersonOutlineIcon />
                </InputAdornment>
              }
              label="Last name"
              sx={{ borderRadius: 4 }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 4 }}>
            <InputLabel htmlFor="email-input">Email</InputLabel>
            <OutlinedInput
              id="email-input"
              type="email"
              error={emailInputHasError}
              value={enteredEmail}
              onChange={emailChangedHandler}
              onBlur={emailBlurHandler}
              startAdornment={
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              }
              label="Email"
              sx={{ borderRadius: 4 }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 4 }}>
            <InputLabel htmlFor="password-input">Password</InputLabel>
            <OutlinedInput
              id="password-input"
              type={showPassword ? "text" : "password"}
              error={passwordInputHasError}
              value={enteredPassword}
              onChange={passwordChangedHandler}
              onBlur={passwordBlurHandler}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPasswordHandler}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              sx={{ borderRadius: 4 }}
            />
            <FormHelperText id="password-confirm">
              Passwords must be at least 8 characters long.
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 4, mb: 2 }}>
            <InputLabel htmlFor="confirm-input">Confirm Password</InputLabel>
            <OutlinedInput
              id="confirm-input"
              type={showConfirmPassword ? "text" : "password"}
              error={passwordConfirmInputHasError}
              value={enteredConfirmPassword}
              onChange={passwordConfirmChangedHandler}
              onBlur={passwordConfirmBlurHandler}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowConfirmPasswordHandler}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm password"
              sx={{ borderRadius: 4 }}
            />
            <FormHelperText id="password-confirm">
              {!passwordsMatch ? "Passwords must match." : " "}
            </FormHelperText>
          </FormControl>
          <div className={classes.recptcha}>
            <ReCAPTCHA
              sitekey="6LdvdL0jAAAAAJhmmv8OrzWYJgp_V4XMLg4NcgPJ"
              onChange={handleRecatchaChange}
            />
          </div>

          <Typography align="center" sx={{ mt: 4 }}>
            <Button
              variant="outlined"
              endIcon={<LoginIcon />}
              onClick={signupHandler}
              sx={{ borderRadius: 4 }}
              disabled={processing || !formIsValid}
            >
              Sign Up
            </Button>
          </Typography>

          <Typography align="center" sx={{ mt: 4 }}>
            Do you have an account?{" "}
            <Link href="#" underline="none" onClick={handleLogin}>
              Sign In.
            </Link>
          </Typography>

          <Typography align="center" sx={{ mt: 1 }}>
            {lastError && <p className={classes.error}>{lastError}</p>}
          </Typography>
        </div>
      </form>
    </Card>
  );
};

export default SignUp;
