import Axios from "axios";
import { useSelector } from "react-redux";

export const useApiClient = () => {
  const token = useSelector((state) => state.auth.token);

  const getUrl = (path) => `api/${path}`;

  const config = {
    headers: { "HB-Auth-Token": token },
  };

  const signupUser = (
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    recaptcha
  ) =>
    Axios.post(getUrl("signup"), {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      recaptcha,
    });

  const reconnect = (email) =>
    Axios.post(getUrl("reconnect"), { email }, config);

  const login = (email, password) =>
    Axios.post(getUrl("signin"), { email, password });

  const upsertProperty = (property) =>
    Axios.post(getUrl("EditProperty"), property, config);

  const getItems = (propertyId) =>
    Axios.get(getUrl(`GetPropertyItems?propertyId=${propertyId}`), config);

  const upsertItem = (item) => Axios.post(getUrl("EditItem"), item, config);

  const removeItem = (item) =>
    Axios.delete(getUrl("RemoveItem"), { ...config, data: item });

  const removeProperty = (property) =>
    Axios.delete(getUrl("RemoveProperty"), { ...config, data: property });

  return {
    signupUser,
    reconnect,
    login,
    upsertProperty,
    removeProperty,
    getItems,
    upsertItem,
    removeItem,
  };
};
