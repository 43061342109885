import { useState } from "react";
import { useDispatch } from "react-redux";

import { useApiClient } from "./api-client";
import { authActions } from "../store/auth-slice";
import { uiActions } from "../store/ui-slice";

export const useAsyncActions = () => {
  const apiClient = useApiClient();
  const [lastError, setLastError] = useState("");
  const dispatch = useDispatch();

  const isUnauthorizedResponse = (error) =>
    error.response && error.response.status === 401;

  const asyncBlock = async (fn, errorMessage, checkAuth) => {
    dispatch(uiActions.startProcess());
    try {
      await fn();
    } catch (error) {
      if (checkAuth && isUnauthorizedResponse(error)) {
        dispatch(authActions.logout());
      } else {
        setLastError(errorMessage);
      }
    }
    dispatch(uiActions.endProcess());
  };

  const singupUser = (
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    recaptcha
  ) => {
    return async (dispatch) =>
      await asyncBlock(async () => {
        await apiClient.signupUser(
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          recaptcha
        );
        dispatch(authActions.signupComplete(email));
      }, "Cannot sign up user, your information is invalid or your email is already in use.");
  };

  const dispatchLoginSucceeded = (
    dispatch,
    email,
    token,
    properties,
    metadata
  ) => {
    dispatch(authActions.loginSucceeded({ email, token }));
    dispatch(uiActions.init({ properties, metadata }));
  };

  const reconnectSession = (token, email) => {
    return async (dispatch) => {
      const reconnect = async () => {
        const response = await apiClient.reconnect(email);
        return response.data;
      };

      dispatch(uiActions.startProcess());
      try {
        const response = await reconnect();
        if (response.isValid) {
          dispatchLoginSucceeded(
            dispatch,
            email,
            token,
            response.properties,
            response.metadata
          );
        } else {
          dispatch(authActions.logout());
        }
      } catch (error) {
        dispatch(authActions.logout());
      }
      dispatch(uiActions.endProcess());
    };
  };

  const login = (email, password) => {
    return async (dispatch) => {
      const tryLogin = async () => {
        const response = await apiClient.login(email, password);
        return response.data;
      };

      await asyncBlock(async () => {
        const response = await tryLogin();
        if (response.isValid) {
          dispatchLoginSucceeded(
            dispatch,
            email,
            response.token,
            response.properties,
            response.metadata
          );
        }
      }, "Login failed for this user name and password combination.");
    };
  };

  const loadPropertyItems = (property) => {
    return async (dispatch) => {
      const fetchPropertyItems = async () => {
        const response = await apiClient.getItems(property.id);
        return response.data;
      };

      await asyncBlock(
        async () => {
          const items = await fetchPropertyItems();
          dispatch(
            uiActions.loadProperty({
              property,
              items,
            })
          );
        },
        "Cannot retrieve property items.",
        true
      );
    };
  };

  const addNewProperty = (property) => {
    return async (dispatch) => {
      const addPropertyAsync = async () => {
        const response = await apiClient.upsertProperty(property);
        return response.data;
      };

      await asyncBlock(
        async () => {
          const newProperty = await addPropertyAsync();
          dispatch(uiActions.addNewProperty(newProperty));
        },
        "Cannot add this property, try again!",
        true
      );
    };
  };

  const addNewItem = (item) => {
    return async (dispatch) => {
      const addItemAsync = async () => {
        const response = await apiClient.upsertItem(item);
        return response.data;
      };

      await asyncBlock(
        async () => {
          const itemId = await addItemAsync();
          dispatch(uiActions.addItem({ ...item, id: itemId }));
        },
        "Adding item failed.",
        true
      );
    };
  };

  const updateItem = (item) => {
    return async (dispatch) =>
      await asyncBlock(
        async () => {
          await apiClient.upsertItem(item);
          dispatch(uiActions.updateItem(item));
        },
        "Update item failed.",
        true
      );
  };

  const removeItem = (item) => {
    return async (dispatch) =>
      await asyncBlock(
        async () => {
          await apiClient.removeItem(item);
          dispatch(uiActions.removeItem(item));
        },
        "Failed to remove this item.",
        true
      );
  };

  const removeProperty = (property) => {
    return async (dispatch) =>
      await asyncBlock(
        async () => {
          await apiClient.removeProperty(property);
          dispatch(uiActions.removeProperty(property));
        },
        "Failed to remove this property.",
        true
      );
  };

  return {
    lastError,
    singupUser,
    reconnectSession,
    login,
    loadPropertyItems,
    addNewProperty,
    removeProperty,
    addNewItem,
    updateItem,
    removeItem,
  };
};
